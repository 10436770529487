.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;  
}
.Left-t{
    display: flex;
    gap: 2rem;
    flex: 1 1;
    text-transform: uppercase;
    flex-direction: column;
    color: white;
}
.Left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.Left-t>:nth-child(2),
.Left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.Left-t>:nth-child(4){
   text-transform: none;
   text-align: justify;
   letter-spacing: 2px;
   line-height: 40px;

}
.Right-t{
    flex: 1 1;
    position: relative;
}
.Right-t>img{
    position: absolute;
    widows: 17rem;
    height: 20rem;
    object-fit: contain;
    right: 8rem;
    top: 2rem;
}

.Right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border:2px solid orange;
    right: 9rem;
    left: 14rem;
    background-color: transparent;
}
.Right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background: var(--planCard);
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width:765px) {
    .Testimonials{
        flex-direction: column;
    }
    .Left-t>:nth-child(2),
    .Left-t>:nth-child(3){
        font-size: xx-large;
    }
    .Right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .Right-t>div{
        position: relative;
        display: none;

    }
    .Right-t>img{
     position: relative;
     top: 0;
     right:0;
     align-self: center;
    }
    .Right-t>:last-child{
        display: block;
        bottom: 0;
        left: 0%;
        
    }
    .arrows>img{
        padding: 0.5rem;
    }
}











